import { createMuiTheme } from '@material-ui/core/styles'
import { grey, indigo } from '../../node_modules/@material-ui/core/colors/index'

const defaultTheme = createMuiTheme()

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: indigo[100],
      main: indigo[200],
      dark: indigo[300],
      contrastText: grey[50]
    },
    secondary: {
      light: grey[200],
      main: grey[300],
      dark: grey[500],
      contrastText: grey[900]
    }
  },
  typography: {
    h2: {
      fontSize: '1.75rem',
      fontWeight: 200,
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '3.5rem',
      },
    }, 
    h3: {
      fontWeight: 200,
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '2.75rem',
      },
    }

  }
})

export default theme