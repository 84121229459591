import { Avatar, Button, IconButton } from "@material-ui/core"
import { get } from "app/api"
import Ellipsis from "assets/images/ellipsis-2.1s-64px.gif"
import { CookieStorage } from "cookie-storage"
import jwtDecode from "jwt-decode"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { baseUrl, redirect } from "../app/api"

const storage = new CookieStorage({
  path: "/"
})

const UNSURE = "0"
const LOGGED_IN = "1"
const LOGGED_OUT = "2"

const TIMEOUT = 60

export const GetStarted = () => {
  const [loggedIn, setLoggedIn] = useState(UNSURE)

  useEffect(() => {
    const idToken = storage.getItem(`${baseUrl.cookieScope}.id_token`)
    if (idToken && idToken !== "null") {
      try {
        const decodedToken = jwtDecode(idToken)
        const issuanceExpiration = moment(decodedToken.iat * 1000).add(TIMEOUT, "minutes")
        const diff = moment().diff(issuanceExpiration, "minutes")

        if (diff < 0) {
          setLoggedIn(LOGGED_IN)
        } else {
          setLoggedIn(LOGGED_OUT)
        }
      } catch (ex) {
        setLoggedIn(LOGGED_OUT)
      }
    } else {
      setLoggedIn(LOGGED_OUT)
    }
  }, [])

  if (loggedIn === LOGGED_IN) {
    return <Button size="medium" color="primary" variant="contained" href={`${baseUrl.app}`}>Return to App</Button>
  } else if (loggedIn === LOGGED_OUT) {
    return <Button size="medium" color="primary" variant="contained" href={`${baseUrl.auth}/signup`}>Get Started</Button>
  } else {
    return <div/>
  }
}

export const LoginButton = () => {
  const [avatar, setAvatar] = useState(false)
  const [loggedIn, setLoggedIn] = useState(UNSURE)

  useEffect(() => {
    const fetchLogin = async () => {
      const idToken = storage.getItem(`${baseUrl.cookieScope}.id_token`)
      if (idToken && idToken !== "null") {
        try {
          const decodedToken = jwtDecode(idToken)
          const issuanceExpiration = moment(decodedToken.iat * 1000).add(TIMEOUT, "minutes")
          const diff = moment().diff(issuanceExpiration, "minutes")

          if (diff < 0) {
            const avatar = await get(idToken, "/avatar")
            setAvatar(avatar.data)
            setLoggedIn(LOGGED_IN)
          } else {
            setLoggedIn(LOGGED_OUT)
          }
        } catch (ex) {
          setLoggedIn(LOGGED_OUT)
        }
      } else {
        setLoggedIn(LOGGED_OUT)
      }
    }
    fetchLogin()
  }, [])

  if (loggedIn === LOGGED_IN) {
    return (
      <IconButton aria-label="Return to application" onClick={() => { redirect(baseUrl.app) }}>
        <Avatar alt="Budgetocity™" src={avatar} variant="circular" style={{width: "3rem", height: "3rem"}} />
      </IconButton>
    )
  } else if (loggedIn === LOGGED_OUT) {
    return (
      <Button color="secondary" variant="outlined" onClick={() => { redirect(`${baseUrl.auth}/login`) }}>
        Login
      </Button>
    )
  } else {
    return (
      <Avatar alt="Budgetocity™" src={Ellipsis} variant="circular" style={{width: "4.5rem", height: "4.5rem"}} />
    )
  }
}