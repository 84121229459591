import axios from 'axios'

const w = (typeof window !== `undefined`) ? window : {}

export const redirect = url => {
  w.location = url
}

export const baseUrl = (() => {
  switch (w?.location?.hostname.replace("www.", "")) {
    case "localhost": {
      return {
        website: "http://localhost:8000",
        api: "https://api.sandbox.budgetocity.com",
        app: "http://localhost:3000/",
        auth: "http://localhost:3002",
        cookieScope: "l"
      }
    }
    case "test.budgetocity.com": {
      return {
        website: "https://test.budgetocity.com",
        api: "https://api.test.budgetocity.com",
        app: "https://app.test.budgetocity.com",
        auth: "https://auth.test.budgetocity.com",
        cookieScope: "t"
      }
    }
    case "sandbox.budgetocity.com": {
      return {
        website: "https://sandbox.budgetocity.com",
        api: "https://api.sandbox.budgetocity.com",
        app: "https://app.sandbox.budgetocity.com",
        auth: "https://auth.sandbox.budgetocity.com",
        cookieScope: "s"
      }
    }
    default:
      return {
        website: "https://budgetocity.com",
        api: "https://api.budgetocity.com",
        app: "https://app.budgetocity.com",
        auth: "https://auth.budgetocity.com",
        cookieScope: "p"
      }
  }
})()

export const get = async (idToken, path) => {
  return await axios.get(baseUrl.api + path, {
    headers: {
      "Authorization": idToken
    }
  })
}


