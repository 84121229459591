import { Box, Container, Divider, Grid, Link, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";

export default ({ showImageDisclaimer = false }) => {
  return (
    <React.Fragment>
      <Divider />
      <Container maxWidth="lg">
        <Box py={3}>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} md={3}>
              <List dense>
                <ListItem>
                  <Typography variant="h6" component="h5" gutterBottom>
                    Legal
                  </Typography>
                </ListItem>
                <ListItem button component="a" href="/privacy">
                  <ListItemText primary="Privacy Policy" />
                </ListItem>
                <ListItem button component="a" href="/cookies">
                  <ListItemText primary="Cookies" />
                </ListItem>
                <ListItem button component="a" href="/terms">
                  <ListItemText primary="Terms &amp; Conditions" />
                </ListItem>
                <ListItem button component="a" href="/deletion">
                  <ListItemText primary="User Data Deletion" />
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={12} md={3}>
              <List dense>
                <ListItem>
                  <Typography variant="h6" component="h5" gutterBottom>
                    Company
                  </Typography>
                </ListItem>
                <ListItem button component="a" href="/pricing">
                  <ListItemText primary="Pricing" />
                </ListItem>
                <ListItem button component="a" href="/blog">
                  <ListItemText primary="Blog" />
                </ListItem>
                <ListItem button component="a" href="/support">
                  <ListItemText primary="Support" />
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={12} md={3}></Grid>

            <Grid item xs={12} md={3}>
              Copyright &copy; {moment().format("YYYY")} Budgetocity, LLC.
              <br />
              All rights reserved. <br />
              {showImageDisclaimer && (
                <>
                  Images from{" "}
                  <Link href="https://rawpixel.com">
                    <b>rawpixel.com</b>
                  </Link>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};
