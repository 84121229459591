import { Box, Container, Hidden, Link, makeStyles, Button } from '@material-ui/core'
import LogoImg from 'assets/images/budgetocity-logo.png'
import TextImg from 'assets/images/budgetocity-text.png'
import { LoginButton } from 'components/AuthButtons'
import React from 'react'

const useStyles = makeStyles({
  logo: {
    width: "40px",
    borderRadius: "4px",
    marginRight: "0.5em"
  },
  logoText: {
    maxWidth: "280px"
  }
})

export default () => {
  const classes = useStyles()
  
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Box my={4} display="flex" alignItems="center">
          <Box flexGrow={1} display="flex" flexDirection="row" alignItems="center">
            <Link href="/">
              <img src={LogoImg} className={classes.logo} alt="Budgetocity™"/>              
            </Link>
            <Hidden xsDown>
              <Link href="/">
                <img src={TextImg} className={classes.logoText} alt="Budgeting with a Twist™"/>              
              </Link>
            </Hidden>
          </Box>
          <Box pr={2}>
          <Button color="secondary" href="https://store.budgetocity.com">
            Store
          </Button>
          </Box>
          <Box pr={2}>
          <Button color="secondary" href="/blog">
            Blog
          </Button>
          </Box>
          <Box>
            <LoginButton />
          </Box>
        </Box>
      </Container>

      <Container maxWidth="lg">
        <Hidden smUp>
          <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
            <img src={TextImg} className={classes.logoText} alt="Budgeting with a Twist™"/>    
          </Box>          
        </Hidden>
      </Container>
    </React.Fragment>
  )
}