import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import theme from 'app/theme'

export default function BaseLayout({title, description, image, pageType, indexable = false, children, ...rest}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  
  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />

        <title>{title}</title>

        <meta name="title" content={title||site.siteMetadata.title} />
        <meta name="description" content={description||site.siteMetadata.description} />

        <meta property="og:site_name" content="Budgetocity™" />
        <meta property="og:title" content={title||site.siteMetadata.title} />
        <meta property="og:image" content={image||"https://public.budgetocity.com/images/screenshot-dekstop-mixed-2x.png"} />
        <meta property="og:type" content={pageType||"website"} />
        <meta property="og:description" content={description||site.siteMetadata.description} />

        {rest?.publishedTime && <meta property="article:published_time" content={rest.publishedTime} />}
        {rest?.author && <meta property="article:author" content={rest.author} />}
        {rest?.section && <meta property="article:section" content={rest.section} />}

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title||site.siteMetadata.title} />
        <meta name="twitter:image" content={image||"https://public.budgetocity.com/images/screenshot-dekstop-mixed-2x.png"} />
        <meta name="twitter:description" content={description||site.siteMetadata.description} />

        {indexable &&
          <meta name="robots" content="noindex, nofollow" />
        }

        <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png"/>
        <link rel="icon" type="image/png" sizes="48x48" href="/icons/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="96x96" href="/icons/favicon-96x96.png"/>

        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap" rel="stylesheet"/>
      </Helmet>

      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {children}
      </ThemeProvider>
    </React.Fragment>
  )
}
